import React, { useState } from 'react';
import Navbar from './Navbar';
import { Outlet } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import ConfigAPI from '../config/config';

function Header(props) {
    const [menuListActive, setMenuListActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [keyword, setKeyword] = useState('');
    const navigate = useNavigate();
    let siteConfigData = props.data;

    const showMobMenu = () => {
        setMenuListActive(true);
        document.body.classList.add("scroll-hide");
    };

    const hideMobMenu = () => {
        setMenuListActive(false);
        setSearchActive(false);
        document.body.classList.remove("scroll-hide");
    };

    const showMobSearch = () => {
        setSearchActive(true);
    };

    // on search input submit redirect to search page
    const handleFormInputChange = (event) => {
        event.preventDefault();
        const userInput = keyword;
        setKeyword(userInput);
        // navigate to search page
        navigate('/search', { replace: true,state:{keyword:keyword} });
        setMenuListActive(false);
        setSearchActive(false);
        document.body.classList.remove("scroll-hide");
    }

    // input onchange event
    const handleInputChange = (event) => {
        const value = event.target.value;
        setKeyword(value);
    }
    return (
        <>
            <header className="header" role="navigation" aria-label="main navigation">
                <div className="container header-container">
                    <div className="nabar-branding">
                        <a href="/" title="NBCUniversal Production Safety" className="main-logo">
                            {
                                <img src="../../assets/images/NBCUniversal-Production-Safety.png" alt="NBCUniversal Production Safety" />
                            }
                            
                        </a>
                        <div className="header-tel-info">
                            <form className={`search-bar ${searchActive ? "active" : ""}`} onSubmit={handleFormInputChange}>
                                <input type="text" id="search" value={keyword} onChange={handleInputChange} className="search-field" placeholder="Search" name="search" />
                                <input type="image" src="../../assets/images/icons/right-arrow-icon.svg" alt="search" onChange={handleInputChange} />
                            </form>
                            {/* <div className="tel-wrap">
                                <i className="tel-info-icon"></i>
                                <a href={`tel:800-364-0747`} className="tel-info-text">US:&nbsp; 800-364-0747</a>
                            </div> */}
                        </div>
                        <div className="hamburger-menu" onClick={showMobMenu}>
                            <img src="./../assets/images/icons/hamburger-icon.svg" alt="Menu" />
                        </div>
                    </div>
                    <div className={`menu-nav-links ${menuListActive ? "active" : ""}`}>
                        <div className="mob-menu-close" onClick={hideMobMenu}>
                            <img src="./../assets/images/icons/cross-icon.svg" alt="close" />
                        </div>
                        <div className={`mob-navbar ${searchActive ? "active" : ""}`}>
                            <Navbar data={siteConfigData}/>
                        </div>

                        <div className={`mob-search-menu ${searchActive ? "active" : ""}`} onClick={showMobSearch}>
                            <img src="../../assets/images/icons/search-icon.svg" alt="search" />
                        </div>
                    </div>
                </div>
            </header>
            <Outlet />
        </>
    )
}

export default Header;
