import React, { useState, useEffect } from "react";
import axios from "axios";
import Collapsible from "react-collapsible";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import PageTitle from './PageTitle';

function RoleCityComponent(props) {
  const roleCityData = props.data;
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const handleDownloadLink = async (pid) => {
    const api_url =  process.env.REACT_APP_CMS_API
    try {
      const res = await axios.get(api_url + '/api/v1/download?pid=' + pid);
      if (typeof res.data === "object") {
        let alink = document.createElement('a');
        alink.href = res.data['path'];
        alink.click();
      }
    } catch (err) {
      return console.error("Fetching data failed", err);
    }
  }

  return (
    <>
      <PageTitle slugValue={props.title} />
      {roleCityData ? (
        roleCityData.data ? (
          <section>
              <div>
                {/* banner section */}
                <section className="main-banner-wrapper">
                  <div className="container banner-container banner-heading">
                    <h1 className="heading">{roleCityData.data.title}</h1>
                  </div>
                </section>
                <section className="container inner-container">
                  <div className="productions-wrapper">
                    {/* Department links */}
                    {roleCityData.data.related.length > 0 ? (
                      <div className="details-section hide-mob-details-section">
                        <div className="notes-details">
                          <h6>RELEVANT PAGES</h6>

                          <ul className="department-names">
                            {roleCityData.data.related.map(
                              (linkData, index1) => (
                                <li key={index1}>
                                  <a href={linkData.url}>{linkData.title}</a>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      null
                    )}
                    <div className="guidance-wrapper accordion-section">
                      <h6 className="title">{roleCityData.data.heading_title}</h6>
                      <div className="content">
                        {parse(roleCityData.data.body)}
                      </div>
                      {/* accordion section */}
                      <div className="wrapper guidance-accordion-wrapper">
                        {roleCityData.data.accordions.map(
                          (innerData, index2) => {
                            return (
                              innerData.ac_title ? <div key={index2} className="custom-accordion">
                                <Collapsible
                                  trigger={
                                    <h5 className="accordion-title">
                                      {innerData.ac_title}
                                    </h5>
                                  }
                                  transitionTime="450"
                                  easing="ease-in-out"
                                  transitionCloseTime="350"
                                >
                                  <div className="accordion-details">
                                    <div className="instruction-section">
                                      <div className="content">
                                        <div>{innerData.ac_description ? parse(innerData.ac_description):""}</div>
                                      </div>
                                      <div>
                                        <div className="btn-wrap">
                                          <div key={index2}>
                                            {innerData.city_guidances.map(
                                              (innerRelatedData, index4) => (
                                                <a href={`${innerRelatedData.url}`} key={index4} className="role-guidance primary-btn">
                                                  {
                                                    innerRelatedData.title
                                                  }
                                                </a>
                                              )
                                            )}
                                            {innerData.role_guidances.map(
                                              (innerRelatedData, index10) => (
                                                <a href={`${innerRelatedData.url}`} key={index10} className="role-guidance primary-btn">
                                                  {
                                                    innerRelatedData.title
                                                  }
                                                </a>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-section">
                                      <h6>Resources</h6>
                                      <div className="form-btns-main">
                                        <div className="form-btns-wrap">
                                          {innerData.forms_documents? (innerData.forms_documents.map(
                                            (documentData, index5) => (
                                              documentData.type ? <a
                                                href={documentData.type === 'document' ? documentData.doc.uri : documentData.link.uri}
                                                target='_blank'
                                                rel="noreferrer"
                                                key={index5}
                                                className="form-document-btn"
                                              >
                                                <span>
                                                  {documentData.type === 'document'?documentData.doc.title: documentData.link.title}
                                                </span>
                                                {documentData.type === 'document'
                                                  ? (
                                                    <i className="pdf-icon"></i>
                                                  ) : (
                                                    <i className="link-icon"></i>
                                                  )}
                                              </a>:null
                                            )
                                          )):(null)}
                                        </div>
                                        {
                                          innerData?.is_download_allowed? (
                                            <div className="download-all-btn primary-btn" onClick={(e) => handleDownloadLink(innerData.pid)}>
                                              <span>Download All Document Types</span>
                                              <i className="download-icon"></i>
                                            </div>
                                          ) : (
                                            null
                                          )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </Collapsible>
                              </div>
                            :null);
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
          </section>
        ) : null
      ) : null}
    </>
  );
}

export default RoleCityComponent;
