import React, { useState, useEffect } from "react";
import axios from "axios";
import PageNotFound from "../pages/PageNotFound";
import Article from "../pages/Article";
import Home from "../pages/Home";
import RoleCity from "../pages/RoleCity";
import Production from "../pages/Production";
import PasswordEntry from "../pages/PasswordEntry";
import Department from "../pages/Department";
import BasicPage from "../pages/BasicPage";
import Default from "../pages/Default";

function Content(props) {

  const apiSiteURL = process.env.REACT_APP_CMS_API;
  const alias = window.location.pathname
  const [contentData, setContentData] = useState(null);
  const [status, setStatus] = useState(null);
  const [auth, setAuth] = useState(window.sessionStorage.getItem(window.location.pathname));
  const api_url = apiSiteURL + `/api/v1/content?_format=json&alias=`;
  
  useEffect(() => {
    if (alias !== '/') {
      document.body.classList.add("inner-page");
    }
    const getContentData= async () => {
      try {
      const res = await axios.get(api_url + ((alias === '/')?'/home':alias) + (auth?'&auth='+auth:''));
      if (typeof res?.data === "object") {
        setContentData(res.data);
        setStatus(res.data.status);
      } else {
        setContentData(null);
        setStatus(res.data.status);
      }
      return res;
      } catch (error) {
        if (error.response) {
          setStatus(error.response.status);
        } 
      }
    };
    getContentData();
  }, [api_url, alias, auth]);

  if (status === 400) {
    return (
           <PageNotFound title="Page Not Found | NBCUniversal Production Safety"/> 
        )
  }
  if (status === 403) {
    return (
           <PasswordEntry title=""/> 
        )
  }
  else if (status === 200) {
    const type = contentData?.data?.type;
    const title = contentData?.data?.title + " | NBCUniversal Production Safety"
    switch(type) {
    case 'dashboard':
      return (
           <Home data={contentData} title={props.title}/> 
        )  
    case 'article':
      return (
           <Article data={contentData} title={title}/> 
        )
    case 'page':
      return (
           <BasicPage data={contentData} title={title}/> 
        )
    case 'city_guidance':
    case 'role_guidance':
      return (
           <RoleCity data={contentData} title={title}/> 
        )
    case 'production':
      return (
          <Production data={contentData} title={title} />
        )
    case 'department':
      return (
           <Department data={contentData} title={title}/> 
        )
    default: 
      return (
           <Default title="Access Denied | NBCUniversal Production Safety"/> 
        )
    }
  }
}

export default Content;