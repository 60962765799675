import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout";
import Content from "../pages/Content";
import Login from "../pages/Login";
import CountryProduction from "../pages/CountryProduction";
import InternationalCountryBannerComponent from "../components/InternationlCountryBannerComponent";
import PasswordEntry from "../pages/PasswordEntry";
import Search from "../pages/Search";

function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Content title="Home | NBCUniversal Production Safety" />} />
          <Route path="us-productions" element={<CountryProduction title="US Productions | NBCUniversal Production Safety" />} />
          <Route path="international-productions" element={<InternationalCountryBannerComponent />} />
          <Route path="password-entry" element={<PasswordEntry />} />
          <Route path="search" element={<Search title="Search | NBCUniversal Production Safety" />} />
          <Route path="*" element={<Content />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="user" element={<Login />} />
        <Route path="user/login" element={<Login />} />
        <Route path="admin" element={<Login />} />
      </Routes>
    </BrowserRouter>

  );
}

export default Routing;
