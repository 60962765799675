import React, { useEffect } from 'react';
import DepartmentComponent from '../components/DepartmentComponent';

function Department(props) {
  useEffect(() => {
    document.body.classList.add("inner-page");
  }, []);
  
  return (
    <>
      <main role="main" aria-label="Department">
        <section>
          <DepartmentComponent data={props.data} title={props.title}/>
        </section>
      </main>
    </>
  )
}

export default Department;