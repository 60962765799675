import React, { useEffect } from 'react';
import CountryBannerComponent from '../components/CountryBannerComponent';

function CountryProduction(props) {
    useEffect(() => {
        document.title = props.title;
    }, [props.title]);
    return (
        <>
            <main role="main" aria-label="Country Production">
                <section>
                    <CountryBannerComponent />
                </section>
            </main>

        </>
    )
}

export default CountryProduction;