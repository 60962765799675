import React, { useState, useEffect } from 'react';
import axios from "axios";
import parse from 'html-react-parser';
import { useParams } from "react-router-dom";
import PageTitle from './PageTitle';
function ArticleComponent(props) {
  const articleData = props.data;
  useEffect(() => {
    document.body.classList.add("inner-page");
    document.documentElement.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle slugValue={props.title} />
      {
        articleData ? (
          articleData.data ? (
            <section className="main-inner-section">
              <div className="container banner-container">
                  <div className="article-inner-section">
                    {/* article details section */}
                    <div className="article-section">
                      {/* title */}
                      <h4>{articleData.data.title}</h4>
                      {/* tags */}
                      <div className="article-timestamp">
                        <div className="tags-wrapper">
                          {articleData.data.tags.map((tagData, index1) => {
                            return (
                              <div key={index1} className="tags-name">{tagData.name}</div>
                            )
                          })}

                        </div>
                        {/* new Date(x.date).toLocaleDateString('en-En',{ year: 'numeric', month: 'long', day: 'numeric' }) */}
                        <div className="timestamp">{articleData.data.date}</div>
                      </div>

                      {/* banner image */}
                      {
                        articleData.data?.hero?.length !==0 ? (
                          <div className="article-banner">
                            <img className="" src={articleData.data.hero.uri} alt={articleData.data.hero.alt} />
                          </div>
                        ) : (
                          null
                        )
                      }
                      <div className="mb-5">
                        <div className="article-main-content">
                          {/* article content */}
                          <div className="article-content notes-contact-details ">
                            <div>{parse(articleData.data.body)}</div>

                          </div>
                          {/* Resources section */}
                          {
                            articleData.data?.related?.length !== 0 ? (
                              <div className="resources-section">
                                <h6>RESOURCES</h6>
                                <ul className="resources-list">
                                  {articleData.data.related.map((reslink, index2) => {
                                    return (
                                      <li key={index2}><a href={reslink.url} rel="noreferrer">{reslink.title}</a></li>
                                    )
                                  })}
                                </ul>
                              </div>
                            ) : (
                              null
                            )
                          }
                        </div>
                        <div className="contact-details">
                          <div>
                            {articleData.data.contacts?.map((contData, contIndex) => {
                              return (
                                <div key={contIndex} className="individual-contact-list">
                                  <p className="name">{contData.name}</p>
                                  <p className="email"><a className="email-icon" href={`mailto:${contData.email}`}>{contData.email}</a></p>
                                  <p className="number"><a className="phone-icon" href={`tel:${contData.phone}`}>{contData.phone}</a></p>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </section>
          ) : (
            null
          )
        ) : (
          null
        )
      }
    </>
  )
}

export default ArticleComponent;