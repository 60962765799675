import React, { useState, useEffect } from 'react';
import axios from "axios";
import parse from 'html-react-parser';
import { useParams } from "react-router-dom";
import PageTitle from './PageTitle';

function BasicPageComponent(props) {
    const basic = props.data;
    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, []);
    return (
        <>
            <PageTitle slugValue={props.title} />
            {
                basic ? (
                    basic.data ? (
                        <div>
                            <div>
                                <section className="main-banner-wrapper">
                                    <div className="container banner-container banner-heading">
                                        <h1 className="heading">{basic.data.title}</h1>
                                    </div>
                                </section>
                                <section className="container inner-container">
                                    <div className="productions-wrapper department-wrapper">
                                        {/* banner image */}
                                        {
                                            basic.data?.hero ? (
                                                <div className="department-banner">
                                                    <img className="" src={basic.data.hero.uri} alt={basic.data.hero.alt} />
                                                </div>
                                            ) : (
                                                null
                                            )
                                        }

                                        {/* <div className="department-headings">
                                            <div>{parse(basic.data.body)}</div>
                                        </div> */}
                                    </div>
                                    <div className="department-desc">
                                        <div className="overall-details">
                                            <div className="overview">
                                                <div>
                                                    {parse(basic.data.body)}
                                                </div>
                                            </div>


                                        </div>
                                        {
                                            basic?.data?.related ? (
                                                <div className="notes-details">
                                                    {basic.data.related?.length !==0 ? <h6 className="small-txt">RELEVANT PAGES</h6>: null}
                                                    <ul className="department-names">
                                                        {basic.data.related.map((post, index1) => {
                                                            return (
                                                                <li key={index1}><a href={post.url}>{post.title}</a></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>
                                </section>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                ) : (
                    null
                )
            }
        </>
    )
}

export default BasicPageComponent;
