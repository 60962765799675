import React, { useEffect } from 'react';
import ProductionRoleCityComponent from '../components/RoleCityComponent';

function RoleCity(props) {
  useEffect(() => {
    document.body.classList.add("inner-page");
    const type = props?.data?.data.type;
    if (type == 'role_guidance') {
      document.title = props.title + " | Role Guidance | NBCUniversal Production Safety"
    }
    else { 
      document.title = props.title + " | City Guidance | NBCUniversal Production Safety"
    }
  }, []);
  
  return (
    <>
      <main role="main" aria-label="Production">
        <section>
          <ProductionRoleCityComponent data={props.data} title={props.title}/>
        </section>
      </main>
    </>
  )
}

export default RoleCity;