import React, { useState, useEffect } from 'react';
import axios from "axios";
import parse from 'html-react-parser';
import { useParams } from "react-router-dom";
import PageTitle from './PageTitle';

function DepartmentComponent(props) {
    const departement = props.data;
  useEffect(() => {
    document.body.classList.add("inner-page");
    document.documentElement.scrollTo(0, 0);
  }, []);
    return (
        <>
            <PageTitle slugValue={props.title} />
            {
                departement ? (
                    departement.data ? (
                        <div>
                           
                            <div className="department-data">
                                <section className="main-banner-wrapper">
                                    <div className="container banner-container banner-heading">
                                        <h1 className="heading">{departement.data.title}</h1>
                                    </div>
                                </section>
                                <section className="container inner-container">
                                    <div className="productions-wrapper department-wrapper">
                                        {/* banner image */}
                                        {
                                            departement.data?.hero.length !== 0 ? (
                                                <div className="department-banner">
                                                    <img className="" src={departement.data.hero.uri} alt={departement.data.hero.alt} />
                                                </div>
                                            ) : (
                                                null
                                            )
                                        }

                                        <div className="department-headings">
                                            <div>{parse(departement.data.heading)}</div>
                                        </div>
                                    </div>
                                    <div className="department-desc">
                                        <div className="overall-details">
                                            <div className="overview">
                                                <div>
                                                    {parse(departement.data.body)}
                                                </div>
                                            </div>

                                            <div className="recommended">
                                                <h5>{departement.data.add_heading}</h5>
                                                <p>{departement.data.add_description}</p>
                                                <ul className="custom-links">
                                                    {departement.data.helpful_links.map((trainData, index1) => {
                                                        return (
                                                            <li key={index1}>
                                                                <a href={trainData.link.uri} target="_blank" rel="noreferrer">{trainData.title}<i className="link-icon"></i></a>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>

                                            <div className="contact-details">
                                                <h5>{departement.data.contact_heading}</h5>
                                                <div className="">
                                                    {departement.data.contacts.map((conData, index2) => {
                                                        return (
                                                            <div key={index2} className="individual-contact-list">
                                                                <p className="name">{conData.title}</p>
                                                                <p className="email"><a className="email-icon" href={`mailto:${conData.email}`}>{conData.email}</a></p>
                                                                <p className="number"><a className="phone-icon" href={`tel:${conData.phone}`}>{conData.phone}</a></p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            departement?.data?.related?.length !== 0 ? (
                                                <div className="notes-details">
                                                    <h6 className="small-txt">RELEVANT PAGES</h6>
                                                    <ul className="department-names">
                                                        {departement.data.related.map((post, index1) => {
                                                            return (
                                                                <li key={index1}><a href={post.url}>{post.title}</a></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>
                                </section>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                ) : (
                    null
                )
            }
        </>
    )
}

export default DepartmentComponent;