
import React, { useEffect } from 'react';
import HomeBannerComponent from '../components/HomeBannerComponent';
import HomeGridComponent from '../components/HomeGridComponent';

function Home(props) {
    useEffect(() => {
        document.title = props.title;
    }, [props.title]);
    return (
        <>
            <main role="main" aria-label="main content">
                <HomeBannerComponent data={props.data}/>
                <HomeGridComponent data={props.data}/>
            </main>

        </>
    )
}

export default Home;