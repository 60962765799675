import React, { useEffect } from 'react';
import PasswordEntryComponent from '../components/PasswordEntryComponent';

function PasswordEntry(props) {
    useEffect(() => {
        document.title = "Password Entry | NBCUniversal Production Safety";
        document.body.classList.add("inner-page");
    });
    return (
        <main role="main" aria-label="Password Entry Content">
            <PasswordEntryComponent />
        </main>
    )
}

export default PasswordEntry