import React, { useState, useEffect } from "react";
import CustomDropdown from "./CustomDropdown";
import axios from "axios";
import parse from "html-react-parser";
import {mediadata} from '../config/data';
import { Link, useLocation } from "react-router-dom";
import ConfigAPI from "../config/config";
import ReactPaginate from 'react-paginate';

function SearchComponent() {
    const apiSiteURL =  process.env.REACT_APP_CMS_API
    // Get data from country API and media API
    let locationData = ConfigAPI('location');
    const location = useLocation();
    const data = location.state;
    const searchKeyword = data?.keyword ? data?.keyword : '';

    // Location dropdown associate array with value and label pair
    let locationListData = [];
    if (typeof locationData != "undefined") {
        Object.keys(locationData).map((key1) =>
            locationListData.push({
                value: locationData[key1]["tid"],
                label: locationData[key1]["name"],
            })
            
        );

    }

    // media type dropdown associate array with value and label pair
    const [mediaData,setData1]=useState(mediadata);
    let mediaListData = [];
    if (typeof mediaData != "undefined") {
        Object.keys(mediaData).map((key2) =>
            mediaListData.push({
                value: mediaData[key2]["type"],
                label: mediaData[key2]["title"],
            })
        );
    }
    // pagination set const value
    // const [itemsCount, setItemsCount] = useState();
    var currentPage1 = 0;
    const [currentPage, setCurrentPage] = useState(currentPage1);
    const [pageCount, setPageCount] = useState(0);
    const [keyword1, setKeyword1] = useState(searchKeyword);
    const [selectedeValue1, setSelectedeValue1] = useState('');
    const [selectedeValue2, setSelectedeValue2] = useState('');
    const [searchAPIData, setSearchAPIData] = useState([]);
    const [textResult, setTextResult] = useState(false);
    

    // get value from search input and call api
    const handleFormInputChange = (event) => {
        event.preventDefault();
        const userInput = keyword1;
        setKeyword1(userInput);
        setTextResult(true);
        setCurrentPage(0);
        getSearchData(currentPage, userInput, selectedeValue1, selectedeValue2);
    }

    // input onchange event
    const handleInputChange = (event) => {
        const value = event.target.value;
        setKeyword1(value);
    }

    // get value from country dropdown and call api
    const getCountryValue = async (value) => {
        var countryValue = value;
        setSelectedeValue1(countryValue);
        setCurrentPage(0);

        await getSearchData(currentPage, keyword1, countryValue, selectedeValue2);
    }

    // get value from media types dropdown and call api
    const getMediaValue = async (value) => {
        var mediaValue = value;
        setSelectedeValue2(mediaValue);
        setCurrentPage(0);

        await getSearchData(currentPage, keyword1, selectedeValue1, mediaValue);
    }

    // clear input search value
    const clearInputChange = async () => {
        const userInput = "";
        setKeyword1(userInput);
        setTextResult(false);
        setCurrentPage(0);

        await getSearchData(currentPage, userInput, selectedeValue1, selectedeValue2);
    }

    // useEffect
    useEffect(() => {
        if (keyword1) {
            setTextResult(true);
        }
        getSearchData(currentPage, keyword1, selectedeValue1, selectedeValue2);
    }, [currentPage]);

    // fetch data from search api with query parameters
    const getSearchData = async (currentPage, keyword1, selectedeValue1, selectedeValue2) => {
        let api_url = apiSiteURL + `/api/v1/search?_format=json&page=${currentPage}`;

        if (keyword1) {
            api_url += `&keyword=${keyword1}`;
        }

        if (selectedeValue1) {
            api_url += keyword1 ? `&location=${selectedeValue1}` : `&location=${selectedeValue1}`;
        }

        if (selectedeValue2) {
            api_url += (keyword1 || selectedeValue1) ? `&type=${selectedeValue2}` : `&type=${selectedeValue2}`;
        }

        try {
            const res = await axios.get(api_url);
            if (typeof res.data == "object") {
                setSearchAPIData(res.data);
                setPageCount(res.data.pager.total_pages)
            } else {
                setSearchAPIData(null);
            }
            return res;
        } catch (err) {
            return console.error("Fetching data failed", err);
        }
    }
    //code for handleclick pagination
    const handlePageClick = (event) => {
        const currentPage1 = event.selected;
        setCurrentPage(currentPage1);
        getSearchData(currentPage1, keyword1, selectedeValue1, selectedeValue2);
    };

    // onclick previous pagination for mobile
    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
        getSearchData(currentPage1, keyword1, selectedeValue1, selectedeValue2);
    }

    //onclick next pagination for mobile
    const handleNextPage = () => {
        currentPage1 = currentPage + 1;
        setCurrentPage(currentPage1);
        getSearchData(currentPage1, keyword1, selectedeValue1, selectedeValue2);
    }

    return (
        <div>
            {/* banner section */}
            <section className="main-banner-wrapper">
                <div className="container banner-container">
                    <h1 className="heading">Search</h1>
                    {/* search field */}
                    <div className="search-filters">
                        <div className="search-banner-field">
                            <form onSubmit={handleFormInputChange}>
                                <input
                                    id="search"
                                    className="search-input"
                                    name="search"
                                    placeholder="Search..."
                                    type="text"
                                    value={keyword1}
                                    onChange={handleInputChange}
                                />

                                <button className="search-result">
                                    <i className="right-arrow"></i>
                                </button>
                            </form>
                            {
                                keyword1 && <button className="clear-search" onClick={clearInputChange}>
                                    <i className="close-icon"></i>
                                </button>
                            }

                        </div>

                        {/* filter list dropdown */}
                        <div className="dropdown-filters-wrap">
                            <CustomDropdown
                                placeHolder="Location"
                                options={locationListData}
                                value={selectedeValue1}
                                onChange={getCountryValue}
                            />

                            <CustomDropdown
                                placeHolder="Media Type"
                                options={mediaListData}
                                value={selectedeValue2}
                                onChange={getMediaValue}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="container inner-container">
                <div className="search-details-wrap">
                    <div className="search-result-table">
                        <div className="search-result-title">
                            {textResult && searchAPIData?.rows?.length !== 0 ? <h4>Search Results for "{keyword1}"</h4> : null}
                            {textResult && searchAPIData?.rows?.length !== 0 ?
                                <div className="count-text">
                                    <span>TOTAL RESULTS:</span>
                                    <span>{searchAPIData?.pager?.total_items}</span>
                                </div> : null}
                        </div>

                        <div>
                            {
                                searchAPIData?.rows?.length === 0 ? (
                                    <h4 className="no-result-text">
                                        No results matched your search.
                                    </h4>
                                ) : (
                                    <>
                                        {searchAPIData?.rows?.map((data, index) => {
                                            if (data.type === "production") {
                                                return (
                                                    <div
                                                        className="search-result-row"
                                                        key={index}
                                                    >
                                                        <Link to={`${data.search_api_url}`} className={data.field_visibility ==="Password Protected" ? 'title production-search-title': 'title title-margin'}>
                                                            {parse(data.title)}
                                                        </Link>
                                                        {data.field_tags ? (
                                                            <div className="tags-wrapper">
                                                                {data.field_tags.split(',').map(
                                                                    (prodTags, tagIndex1) => (
                                                                        <div
                                                                            className="tags-name"
                                                                            key={tagIndex1}
                                                                        >
                                                                            {prodTags}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        ) : null}
                                                        <div className="read-more-link">
                                                            <Link to={`${data.search_api_url}`}>
                                                                Read More{" "}
                                                                <i className="right-arrow-blue"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div
                                                    className="search-result-row"
                                                    key={index}
                                                >
                                                    <Link to={`${data.search_api_url}`} className="title">
                                                        {parse(data.title)}
                                                    </Link>
                                                    <div className="content">
                                                        {parse(data.body.substring(0, 280))}
                                                    </div>
                                                    {data.field_tags ? (
                                                        <div className="tags-wrapper">
                                                            {data.field_tags.split(',').map(
                                                                (prodTags, tagIndex1) => (
                                                                    <div
                                                                        className="tags-name"
                                                                        key={tagIndex1}
                                                                    >
                                                                        {prodTags}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : null}
                                                    <div className="read-more-link">
                                                        <Link to={`${data.search_api_url}`}>
                                                            Read More{" "}
                                                            <i className="right-arrow-blue"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                )
                            }

                        </div>
                    </div>
                    {/* desktop pagination */}
                    <div className="desktop-custom-pagination">
                        {searchAPIData?.pager?.total_pages > 1 ?(
                            <ReactPaginate
                                pageCount={pageCount}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName={'custom-pagination'}
                                previousLinkClassName={'page-arrows page-previous'}
                                breakClassName={'page'}
                                nextLinkClassName={'page-arrows page-next'}
                                pageClassName={'page-count'}
                                disabledClassName={'disabled'}
                                activeClassName={'active'}
                            />
                        ) : (
                            null
                        )}
                    </div>
                    {/* mobile pagination */}
                    {searchAPIData?.pager?.total_pages > 1 ? (
                        <div className="mobile-custom-pagination">
                            {/* pagination */}
                            <div className="custom-pagination">
                                <button className="page-arrows page-previous" onClick={handlePreviousPage} disabled={currentPage === 0}>
                                    <span className="title">Previous</span>
                                </button>
                                <div className="page-count-mobile">
                                    <span>{currentPage + 1}</span>
                                    <span>of</span>
                                    <span>{pageCount}</span>
                                </div>
                                <button className="page-arrows page-next" onClick={handleNextPage} disabled={currentPage + 1 === pageCount}>
                                    <span className="title">Next</span>
                                </button>
                            </div>
                        </div>
                    ) : (
                        null
                    )}
                </div>
            </section>
        </div>
    );
}

export default SearchComponent;
