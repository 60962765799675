import React, { useEffect } from 'react';
import BasicPageComponent from '../components/BasicPageComponent';

function BasicPage(props) { 
  useEffect(() => {
    document.body.classList.add("inner-page");
  }, []);

  return (
    <>
      <main role="main" className="basic-page-main" aria-label="Basic Page">
        <section>
          <BasicPageComponent data={props.data} title={props.title}/>
        </section>
      </main>
    </>
  )
}

export default BasicPage;