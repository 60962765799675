import React, { useState, useEffect, useRef } from 'react';
import Dropdown from './Dropdown';

function MenuItems({ items, depthLevel }) {
    const [dropdown, setDropdown] = useState(false);

    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (
                dropdown &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    const closeDropdown = () => {
        dropdown && setDropdown(false);
    };

    const onMouseEnter = () => {
        window.innerWidth > 1024 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 1024 && setDropdown(false);
    };
    return (
        <li
            className="header-menu-items"
            ref={ref}
            onClick={closeDropdown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {items.link && items.below && items.below.length ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {depthLevel === 0 ? (
                            items.title
                        ) : (
                            <a href={items.link}>{items.title}</a>
                        )}

                        {depthLevel > 0 ? null : depthLevel > 0 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className="dropdown-arrow" />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items.below}
                        dropdown={dropdown}
                    />
                </>
            ) : !items.link && items.below ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {items.title}{' '}
                        {depthLevel > 0 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className="dropdown-arrow" />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items.below}
                        dropdown={dropdown}
                    />
                </>
            ) : (
                <a href={items.link} target={items.target === 'new window' ? '_blank' : '_self'}>{items.title}</a>
            )}
        </li>
    )
}

export default MenuItems;