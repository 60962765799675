import React from 'react';
import ConfigAPI from "../config/config";

function HomeBannerComponent(props) {
    let hBannerData = props.data

    return (
        <>
            {
                hBannerData ? (
                    hBannerData.data ? (
                         <div className="home-banner-section">
                            <div className="full-banner-container">
                                <img className="full-width-wrapper desktop-banner-img" src={hBannerData.data.hero.uri} alt={hBannerData.data.hero.alt} />
                                <img className="full-width-wrapper mobile-banner-img" src={hBannerData.data.hero_mobile.uri} alt={hBannerData.data.hero_mobile.alt} />
                            </div>
                            <div className="home-banner-gradient"></div>
                        </div>
                    ) : (
                        null
                    )
                ) : (
                    null
                )
            }
        </>
    )
}

export default HomeBannerComponent;