// import React from 'react';
import MenuItems from './MenuItems';
import React from 'react';
import ConfigAPI from "../config/config";
function Navbar(props) {
    let navbarData = props.data
    
    return (
        <>
            {
                navbarData ? (
                    navbarData.header ? (
                            <nav>
                                <ul className="header-menus">
                                    {navbarData.header.HeaderMenu.map((menu, index) => {
                                        const depthLevel = 0;
                                        if (!menu.parent)
                                        {
                                            return (
                                                <MenuItems
                                                    items={menu}
                                                    key={index}
                                                    depthLevel={depthLevel}
                                                />
                                            );
                                        }
                                        
                                    })}
                                </ul>
                            </nav>
                   ) : (
                    null
                    )
                ) : (
                null
                )
            }
        </>

    )
}

export default Navbar;