import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Production from "../pages/Production";

function PasswordEntryComponent() {
    const apiSiteURL = process.env.REACT_APP_CMS_API;
    const passwordInputRef = useRef();
    const location = useLocation();
    const data = location.state;
    const navigate = useNavigate();
    const [message, setMessage] = useState(false);
    const api_url = apiSiteURL + `/api/v1/content?alias=`+window.location.pathname;
    const [contentData, setContentData] = useState(null);
    const auth = window.sessionStorage.getItem(window.location.pathname);

    const validatePassword = async (e) => {
        e.preventDefault();
        const userData = passwordInputRef.current.value;
        getData(userData);
    };

    const getData = async (userData) => {
        axios(api_url + '&auth=' + userData)
            .then((res) => {

                if (typeof res?.data == "object" && res.data.status == 200) {
                  setMessage(false);
                  setContentData(res.data);
                  window.sessionStorage.setItem(window.location.pathname, userData);
                } else {
                  setMessage(true);
                }
            })
            .catch((error) => {
                setMessage(true);
            });
    }
    useEffect(() => {
        if (auth) {
            getData(auth)
        }
      }, [auth]);

    return (
        auth ?<Production data={contentData} title={contentData.data.title + " | NBCUniversal Production Safety"} />:<section className="main-inner-section">
            <div className="container banner-container">
                <div className="password-content-wrap">
                    <img
                        className="icon"
                        src={process.env.PUBLIC_URL + "/assets/images/icons/icon-lock.svg"}
                        alt="icon"
                    />
                    <h2 className="heading">This page is password protected</h2>

                    {message ? (
                        <h6 className="incorrect-pwd">The password you've entered is incorrect</h6>
                    ) : (
                        <h6 className="sub-heading">Please enter the password to view this content.</h6>
                    )
                    }
                    <form className="passoword-form" onSubmit={validatePassword}>
                        <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            ref={passwordInputRef}
                            className="password-field"
                        />
                        <button className="password-submit">
                            <i className="right-arrow"></i>Log In Now
                        </button>
                    </form>
                    <p className="help-text">
                        Having trouble accessing this page? Contact your EHS representative.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default PasswordEntryComponent;