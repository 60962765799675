import React, { useState, useEffect } from "react";
import axios from "axios";
import Collapsible from "react-collapsible";
import parse from "html-react-parser";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import PageTitle from "./PageTitle";

function ProductionComponent(props) {
  const prodCityRoleData = props.data;

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const handleDownloadLink = async (pid) => {
    const api_url =  process.env.REACT_APP_CMS_API
    try {
      const res = await axios.get(api_url + '/api/v1/download?pid=' + pid);
      if (typeof res.data === "object") {
        let alink = document.createElement('a');
        alink.href = res.data['path'];
        alink.click();
      }
    } catch (err) {
      return console.error("Fetching data failed", err);
    }
  }

  return (
    <>
      <PageTitle slugValue={props.title} />
      {prodCityRoleData ? (
        prodCityRoleData.data ? (
          <section>
              <div>
                {/* banner section */}
                <section className="main-banner-wrapper">
                  <div className="container banner-container banner-heading">
                    <h1 className="heading">{prodCityRoleData.data.title}</h1>
                  </div>
                </section>
                <section className="container inner-container">
                  <div className="productions-wrapper">
                    {/* details section */}
                    {prodCityRoleData.data?.body ? (
                      <div className="details-section">
                        <div className="notes-details notes-contact-details">
                          <div>{parse(prodCityRoleData.data.body)}</div>
                        </div>
                        <div className="contact-details">
                          <h6>{prodCityRoleData.data.contact_heading}</h6>
                          <div>
                            {prodCityRoleData.data.contacts.map((contData, contIndex) => {
                              return (
                                <div key={contIndex} className="individual-contact-list">
                                  <p className="name">{contData.name}</p>
                                  <p className="email"><a className="email-icon" href={`mailto:${contData.email}`}>{contData.email}</a></p>
                                  <p className="number"><a className="phone-icon" href={`tel:${contData.phone}`}>{contData.phone}</a></p>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      null
                    )}
                    {/* accordion section */}
                    <div className="accordion-section">
                      <div className="wrapper">
                        {prodCityRoleData.data.accordions.map(
                          (innerData, index1) => {
                            return (
                              innerData.ac_title ? <div key={index1} className="custom-accordion">
                                <Collapsible
                                  trigger={
                                    <h5 className="accordion-title">{innerData.ac_title}</h5>
                                  }
                                  transitionTime="450"
                                  easing="ease-in-out"
                                  transitionCloseTime="350"
                                >
                                  <div className="accordion-details">
                                    <div className="instruction-section">
                                      <div className="content">
                                        <div>{parse(innerData.ac_description?innerData.ac_description:"")}</div>
                                      </div>

                                      <div>
                                        <div key={index1} className="btn-wrap">
                                          {innerData.city_guidances.map(
                                            (innerRelatedData, index2) => (
                                              <div key={index2}>
                                                <a href={`${innerRelatedData.url}`} className="role-guidance primary-btn">
                                                  {
                                                    innerRelatedData.title
                                                  }
                                                </a>
                                              </div>
                                            )
                                          )}
                                          {innerData.role_guidances.map(
                                            (innerRelatedData, index3) => (
                                              <div key={index3}>
                                                <a href={`${innerRelatedData.url}`} className="role-guidance primary-btn">
                                                  {
                                                    innerRelatedData.title
                                                  }
                                                </a>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>

                                    </div>
                                    <div className="form-section">
                                      <h6>Resources</h6>
                                      <div className="form-btns-main">
                                        <div className="form-btns-wrap">
                                          {innerData.forms_documents? (innerData.forms_documents.map(
                                            (documentData, index5) => (
                                              documentData.type ? <a
                                                href={documentData.type === 'document' ? documentData.doc?.uri : documentData.link?.uri}
                                                target='_blank'
                                                rel="noreferrer"
                                                key={index5}
                                                className="form-document-btn"
                                              >
                                                <span>
                                                  {documentData.type === 'document'?documentData.doc?.title: documentData.link?.title}
                                                </span>
                                                {documentData.type === 'document'
                                                  ? (
                                                    <i className="pdf-icon"></i>
                                                  ) : (
                                                    <i className="link-icon"></i>
                                                  )}
                                              </a>:null
                                            )
                                          )):(null)}
                                        </div>
                                        {
                                          innerData?.is_download_allowed? (
                                            <div className="download-all-btn primary-btn" onClick={(e) => handleDownloadLink(innerData.pid)}>
                                              <span>Download All Documents</span>
                                              <i className="download-icon"></i>
                                            </div>
                                          ) : (
                                            null
                                          )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </Collapsible>
                              </div>
                            :null)
                          })}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
          </section>
        ) : null
      ) : null}
    </>
  )
}

export default ProductionComponent;
