import React, { useState, useEffect } from 'react';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';

function Layout() {
  const apiSiteURL = process.env.REACT_APP_CMS_API;
  const [menuData, setMenuData] = useState(null);
  const api_url = apiSiteURL + `/api/v1/menu?_format=json`;
  useEffect(() => {
    const getMenuData= async () => {
      try {
        const res = await axios.get(api_url);
        if (typeof res?.data == "object") {
          setMenuData(res.data);
        } else {
          setMenuData(null);
        }
        return res;
      } catch (error) {
      }
    };
    getMenuData();
  }, [api_url]);

    return (
        <>
            <Header data={menuData}/>
            <Footer data={menuData}/>
        </>
    )
}

export default Layout;