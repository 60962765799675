import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import ConfigAPI from '../config/config';
import axios from "axios";
import {mediadata} from '../config/data';
import ReactPaginate from 'react-paginate';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';

function InternationalCountryBannerComponent() {
  // Get data from country API and media API
  let locationData = ConfigAPI('location');
  const apiSiteURL = process.env.REACT_APP_CMS_API
  const pageTitleValue = "International Productions | NBCUniversal Production Safety";
  //setting the initial value for pagination
  var newCurrentPage = 0;
  
  const [data,setData1]=useState(mediadata);
  const [countryData, setcountryData] = useState({});
  const [searchValue, setSearchValue] = useState("")
  const [searchInputValue, setSearchInputValue] = useState("");
  const [filterValue1, setFilterValue1] = useState(false);
  const [filterValue2, setFilterValue2] = useState(false);
  const [multilocation, setMultilocation] = useState([]);
  const [multitypes, setMultitypes] = useState([]);
  // const [itemsCount, setItemsCount] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  // const [locData, setLocData] = useState([]);
  const [currentPage,setCurrentPage] =useState(newCurrentPage);

//Reseting the input value 
const  handleReset = async() =>{
  let userInput = "";
  setSearchValue(userInput);
  setSearchInputValue(userInput)
  GetFilterData();
};

  const showTypesFilter = () => {
    if (window.innerWidth <= 1024) {
      setFilterValue1(!filterValue1);
    }
  };

  const showLocationsFilter = () => {
    if (window.innerWidth <= 1024) {
      setFilterValue2(!filterValue2);
    }
  };

  
 //get slug value of all non us countries name
  const GetFilterData = () => {
    let url = apiSiteURL + `/api/v1/intl-productions?_format=json&page=${newCurrentPage}${searchValue ? `&keyword=${searchValue}` : ""}${multitypes.length > 0 ? `&type=${multitypes.join(',')}` : ""}${multilocation.length > 0 ? `&location=${multilocation.join(',')}` : ""}`
    fetch(url)
  }

  const fetch = async (url) => {
    try {
      const { data } = await axios.get(url);
      setcountryData(data)
      setPageCount(data.pager.total_pages)
      // setItemsCount(data.length)

    } catch (err) {
      console.error(err);
    }
  };

  //internation data on load(); 
  useEffect(() => {
    const timer = setTimeout(() => {
      GetFilterData();
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchValue,newCurrentPage]);

  //started code for multiple check box for location
  const updateMultilocation = (e) => {
    let loc = e.target.value
    let multiloc = multilocation
    if (e.target.checked) {
      multilocation.push(loc.toLowerCase())
    }
    else {
      multilocation.splice(multilocation.indexOf(loc).toString().toLowerCase(), 1)
    }
    setCurrentPage(0)
    GetFilterData()
  }
  //started code for multiple check box for Content Types
  const updateMultiTypes = (e) => {
    let types = e.target.value
    let multiCtTypes = multitypes
    if (e.target.checked) {
      multitypes.push(types)
    }
    else {
      multitypes.splice(multitypes.indexOf(types), 1)
    }
    setCurrentPage(0)
    GetFilterData()
  }
  //code for handleclick pagination
  const handlePageClick = (data) => {
    newCurrentPage = data.selected;
    setCurrentPage(newCurrentPage);
    GetFilterData()
  };

  const handleFormInputChange = (event) => {
    event.preventDefault();
    if (event.key === 'Enter') {
      // const value = event.target.value;
      setSearchInputValue(event.target.value);
      setCurrentPage(0)
      GetFilterData();
    }
  }
  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
  }
  // onclick previous pagination for mobile
  const handlePreviousPage = () => {
    newCurrentPage = currentPage - 1;
    setCurrentPage(newCurrentPage);
    GetFilterData()
  }

  // onclick next pagination for mobile
  const handleNextPage = () => {
    newCurrentPage = currentPage + 1;
    setCurrentPage(newCurrentPage);
    GetFilterData()
  }
  return (
    <>
      <HelmetProvider>
          <Helmet>
            <title>{pageTitleValue}</title>
            <meta property="og:title" content={pageTitleValue} />
          </Helmet>
      </HelmetProvider>
      <div>
        {/* banner section */}
        <section className="main-banner-wrapper">
          <div className="container banner-container">
            <h1 className="heading">International Productions</h1>
            {/* search field */}
            <div className="search-banner-field">
              <input id="search" className="search-input" autoComplete='off' name="search" placeholder="Search productions or roles..."
                type="text" onKeyUp={handleFormInputChange} value={searchValue}  onChange={handleInputChange} />
              <button  className="clear-search" onClick={handleReset}>
                <i className="close-icon"></i>
              </button>
            </div>
          </div>
        </section>
        <section className="container inner-container">
          <div className="production-details-wrap">
            {/* filter list checkboxes */}
            <div className="filter-list-wrap">
              <div className={`listing ${filterValue1 ? "active" : ""}`}>
                <h6 onClick={showTypesFilter}>Types</h6>
                <ul className="filter-checkbox-list">
                  {data.map((ct,indexCt) => {
                    if(ct.type ==="city_guidance"||ct.type ==="role_guidance"||ct.type==="production"){
                      return(
                        <li key={indexCt}>
                          <input className="custom-checkbox" onChange={(event) =>{updateMultiTypes(event);GetFilterData()}} name="ct" id={"custom-checkbox-ct-"+indexCt} type="checkbox" value={ct.type} />
                          <label htmlFor={"custom-checkbox-ct-"+indexCt}>{ct.title}</label>
                      </li>
                      )
                    }
                  })}
                </ul>
              </div>
              {/* location list */}
              <>
                {
                  locationData ? (
                    // locationData.data ? (
                    <div className={`listing ${filterValue2 ? "active" : ""}`}>
                      <h6 onClick={showLocationsFilter}>Location</h6>
                      <ul className="filter-checkbox-list">
                      {locationData.map((item, index) => {
                                if(item['name']!== "US"){
                                    return(
                                      <li key={index}>
                                        <input className="custom-checkbox" onChange={(event) =>{updateMultilocation(event)}} id={"custom-checkbox-loc-"+index} type="checkbox" value={item.tid} />
                                        <label htmlFor={"custom-checkbox-loc-"+index}>{item.name}</label>
                                      </li>
                                    )

                                }
                            })}
                      </ul>
                    </div>
                  ) : (
                    null
                  )
                }
              </>
            </div>
            {/* card details */}
            <div className="card-list">
            {searchInputValue && countryData?.rows?.length !== 0 ? <h4 className="searh-result-text">Search Results for "{searchInputValue}"</h4> : null}
              <div className="production-card-list">
                {
                  countryData?.rows?.length === 0 ? (
                    <h4 className="no-result-text">
                        No results matched your search criteria.
                    </h4>
                  ) : (
                  <>
                    {countryData?.rows?.map((countData, typeIndex) => {
                        return (
                          <Link key={typeIndex} to={`${countData.search_api_url}`} className={'production-card ' + countData.type + '-card'}>
                            <div className={countData.field_visibility ==="Password Protected" ? 'icon-lock':''}></div>
                            <div className="production-img">
                              <img className="" src={process.env.PUBLIC_URL + '/assets/images/icons/' + countData.type + '-icon.svg'} alt={countData.type} />
                            </div>
                            <div className='production-details'>
                              <h6 className="name">{parse(countData.title)}</h6>
                              <span className="city-name">{countData.field_location}</span>
                            </div>
                          </Link>
                        )
                    })}
                  </>
                  )
                }
              </div>
            
              {/* desktop pagination */}
              <div className="desktop-custom-pagination">
                  {countryData?.pager?.total_pages > 1 ? (
                      <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'custom-pagination'}
                        previousLinkClassName={'page-arrows page-previous'}
                        breakClassName={'page'}
                        nextLinkClassName={'page-arrows page-next'}
                        pageClassName={'page-count'}
                        disabledClassName={'disabled'}
                        activeClassName={'active'}
                        forcePage={currentPage}
                        />
                        ) : (
                            null
                        )}
                    </div>
                    {/* mobile pagination */}
                    {countryData?.pager?.total_pages > 1 ? (
                          <div className="mobile-custom-pagination">
                          {/* pagination */}
                          <div className="custom-pagination">
                              <button className="page-arrows page-previous" onClick={handlePreviousPage} disabled={currentPage === 0}>
                                  <span className="title">Previous</span>
                              </button>
                              <div className="page-count-mobile">
                                  <span>{currentPage + 1}</span>
                                  <span>of</span>
                                  <span>{pageCount}</span>
                              </div>
                              <button className="page-arrows page-next" onClick={handleNextPage} disabled={currentPage + 1 === pageCount}>
                                  <span className="title">Next</span>
                              </button>
                          </div>
                      </div>
                      ) : (
                          null
                      )}
              </div>
            </div>
        </section >
      </div>
    </>
  )
}

export default InternationalCountryBannerComponent;