import React, { useEffect, useRef, useState } from "react";

function CustomDropdown({ placeHolder, options, onChange }) {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const handleInputClick = (e) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }

    return selectedValue.label;
  };

  const onItemClick = (option) => {
    let newValue;
    newValue = option;
    setSelectedValue(newValue);
    onChange(newValue.value);
  };

  const isSelected = (option) => {
    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === option.value;
  };

  return (
    <div className={`custom-dropdown-container ${showMenu ? "active" : ""}`}>
      <div ref={inputRef} onClick={handleInputClick} className="custom-dropdown-input" tabIndex="0">
        <div className="dropdown-selected-value">{getDisplay()}</div>
      </div>
      {showMenu && (
        <div className="custom-dropdown-menu">
          {options.map((option) => (
            <div
              onClick={() => onItemClick(option)}
              key={option.value}
              className={`custom-dropdown-item ${isSelected(option) && "selected"}`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
