import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function PageTitle({ slugValue }) {
    const pageTitleValue = slugValue;
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{pageTitleValue}</title>
                    <meta property="og:title" content={pageTitleValue} />
                </Helmet>
            </HelmetProvider></>
    )
}

export default PageTitle;