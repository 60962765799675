import { useEffect, useState } from "react";
import axios from 'axios';

// common API call for all REST API's based on param
const ConfigAPI = function (param) {
  const [responseData, setAPIData] = useState([]);
  const [error, setError] = useState(null);
  let api_url = '';
  const apiSiteURL = process.env.REACT_APP_CMS_API;
  switch (param) {
    // case 'header':
    //   api_url = apiSiteURL + `/api/v1/menu?_format=json`
    //   break;
    // case 'footer':
    //   api_url = apiSiteURL + `/api/v1/menu/footer-menu`
    //   break;
    // case 'home':
    //   api_url = apiSiteURL + `/nbcuehs/home-dashboard/home`
    //   break;
    // case 'article':
    //   api_url = apiSiteURL + `/nbcuehs/article/article`
    //     break;
    case 'location':
      api_url = apiSiteURL + `/api/v1/location?_format=json`
        break;
    case 'uscountry':
      api_url = apiSiteURL + `/nbcuehs/multisearch/`
        break;
    case 'country':
      api_url = apiSiteURL + `/nbcuehs/country`
      break;
    case 'media':
      api_url = apiSiteURL + `/nbcuehs/media`
      break;
    case 'siteconfig':
      api_url = apiSiteURL + `/nbcuehs/site-config/`
      break;
    default:
      break;
  }
  useEffect(() => {
    if (api_url) {
      axios(api_url)
        .then((response) => {
          setError(null);
          setAPIData(response.data);
        })
        .catch(setError);
    }
  }, []);
  return responseData;
}
export default ConfigAPI;