import React from 'react';
import ConfigAPI from "../config/config";

function HomeGridComponent(props) {
  let hGridData = props.data;

  return (
    <>
      {
        hGridData ? (
          hGridData.data ? (
            <div>
              <div className="home-grid-section">
                <div>
                  <h4 className="section-title">{hGridData.data.title}</h4>
                  <div className="section-list">
                    {/* individual section */}
                    {hGridData.data.grids.map((sec, index2) => {
                      return (
                        <a key={index2} href={sec.link.uri} className="individual-section" target={sec.link.options.attributes.target}>
                          {
                            sec?.image ? (
                              <div className="section-image">
                                <img src={sec.image.uri} alt={sec.image.alt} />
                              </div>
                            ) : (
                              null
                            )
                          }

                          <div className="section-content">
                            <h6>{sec.link.title}</h6>
                          </div>
                        </a>
                      )
                    })}

                  </div>
                </div>
              </div>
            </div>
          ) : (
            null
          )
        ) : (
          null
        )
      }
    </>

  )
}

export default HomeGridComponent;