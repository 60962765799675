import React, { useEffect } from 'react';
import ProductionComponent from '../components/ProductionComponent';

function Production(props) {
  useEffect(() => {
    document.body.classList.add("inner-page");
  }, []);
  
  return (
    <>
      <main role="main" aria-label="Production">
        <section>
          <ProductionComponent data={props.data} title={props.title}/>
        </section>
      </main>
    </>
  )
}

export default Production;