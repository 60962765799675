import React, { useEffect } from 'react';

function PageNotFound(props) {
  useEffect(() => {
    document.title = props.title;
    document.body.classList.add("inner-page");
  }, [props.title]);

  return (
    <>
      <main>
        <section className="main-inner-section">
          <div className="container banner-container">
            <div className="page-not-found-wrap">
              <h1 className="mb-3">404 Error</h1>
              <h5>Sorry, this page cannot be found.</h5>
            </div>
          </div>
        </section>
      </main>

    </>
  )
}

export default PageNotFound;