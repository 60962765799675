import React, { useEffect } from 'react';
import SearchComponent from '../components/SearchComponent';

function Search(props) {
    useEffect(() => {
        document.title = props.title;
    }, [props.title]);

    return (
        <>
            <main role="main" aria-label="Search">
                <section>
                    <SearchComponent />
                </section>
            </main>

        </>
    )
}

export default Search;