export const mediadata=[
    {
      "id":1,
      "type": "production",
      "title": "Production"
    },
    {
      "id":2,
      "type": "role_guidance",
      "title": "Role Guidance"
    },
    {
      "id":3,
      "type": "city_guidance",
      "title": "City Guidance"
    },
    {
      "id":4,
      "type": "article",
      "title": "Article"
    },
    {
      "id":4,
      "type": "department",
      "title": "Department"
    },
]