import React, { useEffect } from 'react';
import ArticleComponent from '../components/ArticleComponent';

function Article(props) {
  useEffect(() => {
    document.body.classList.add("inner-page");
  }, []);

  return (
    <main role="main" aria-label="Article Content">
        <ArticleComponent data={props.data} title={props.title}/>
    </main>
  )
}

export default Article